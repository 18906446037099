import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import checkProps from '@jam3/react-check-extra-props';
import dynamic from 'next/dynamic';
import classNames from 'classnames';
import Nav from '../Nav/Nav';
import VerticleNav from '../VerticleNav/VerticleNav';
import Footer from '../Footer/Footer';
import detect from '../../utils/detect';
import styles from './Layout.module.scss';

const RotateScreen = dynamic(() => import('../RotateScreen/RotateScreen'), { ssr: false });

function Layout({
  children,
  isDashboard = true,
  isInnerNav = true,
  activeDefaultSubnav = false,
  subNavComponent = null,
  pageTitle = ''
}) {
  const [toggleSubMenu, setToggleSubMenu] = useState(activeDefaultSubnav || false);

  const toggleSubnavHandler = () => {
    setToggleSubMenu(!toggleSubMenu);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (detect.isMobile || window.innerWidth < 1024) {
        console.log(detect.isMobile);
        setToggleSubMenu(false);
      }
    }
  }, []);

  return isDashboard ? (
    <div className={styles.dashboard}>
      <VerticleNav toggleActive={classNames(toggleSubMenu ? styles.isActive : '')} />
      <div className={styles.wrapper}>
        {isInnerNav ? (
          <div className={classNames(styles.subNavWrapper, toggleSubMenu ? styles.isActive : '')}>
            {subNavComponent}
          </div>
        ) : null}
        <div className={classNames(styles.main, toggleSubMenu ? styles.activeSubNav : '')}>
          <Nav
            isSubnav={isInnerNav}
            toggleSubMenu={toggleSubMenu}
            toggleHandler={toggleSubnavHandler}
            pageTitle={pageTitle || ''}
          />
          <div className={styles.pageWrapper}>{children}</div>
        </div>
      </div>
      <Footer />
      <RotateScreen />
    </div>
  ) : (
    <div>
      {children}
      <RotateScreen />
    </div>
  );
}

Layout.propTypes = checkProps({
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  isDashboard: PropTypes.bool,
  activeDefaultSubnav: PropTypes.bool,
  pageTitle: PropTypes.string,
  subNavComponent: PropTypes.element,
  isInnerNav: PropTypes.bool
});

Layout.defaultProps = {
  isInnerNav: true
};

export default Layout;
