import React, { memo, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import checkProps from '@jam3/react-check-extra-props';
import { Form, Schema, ButtonToolbar, Button, Loader, Message } from 'rsuite';
import { useRouter } from 'next/router';
import TextField from '../../common/TextField';
import usePost from '../../../hooks/usePost';
import { setAuthUser } from '../../../redux/actions/user';
import styles from './Login.module.scss';

const { StringType } = Schema.Types;
const model = Schema.Model({
  password: StringType().isRequired('This field is required.'),
  email: StringType().isEmail('Please enter a valid email address.').isRequired('This field is required.')
});

function Login({ className }) {
  const dispatch = useDispatch();
  const post = usePost();
  const [formErrors, setFormErrors] = useState([]);
  const [message, setMessage] = useState();
  const [isSubmit, setIsSubmit] = useState(false);
  const router = useRouter();
  let loginForm = useRef();
  const handleSubmit = async () => {
    setIsSubmit(true);
    if (!loginForm.check()) {
      setIsSubmit(false);
      return;
    }
    const userData = loginForm.state.formValue;

    const { ok, status, data } = await post('/api/auth', userData);
    if (ok) {
      let userdata = data;
      let nDate = new Date();
      nDate.setSeconds(nDate.getSeconds() + data.expires_in - 300);
      userdata.expires_in = new Date(nDate);
      dispatch(setAuthUser(userdata));
      return router.push('/dashboard');
    } else {
      setMessage(status === 401 ? 'Incorrect email address or password.' : data);
      setIsSubmit(false);
    }
  };

  return (
    <div className={classnames(styles.Login, className)}>
      {message ? <Message className={styles.error} showIcon type="error" description={message} /> : null}

      <Form
        ref={(ref) => (loginForm = ref)}
        fluid
        checkTrigger={'blur'}
        onCheck={(formError) => {
          setFormErrors({ formError });
        }}
        model={model}
        onSubmit={handleSubmit}
      >
        <TextField error={formErrors?.formError?.email} name="email" label="Email Address" />
        <TextField error={formErrors?.formError?.password} name="password" type="password" label="Password" />
        <ButtonToolbar>
          <Button appearance="primary" type="submit">
            {isSubmit ? <Loader /> : 'Sign In'}
          </Button>
        </ButtonToolbar>
      </Form>
    </div>
  );
}

Login.propTypes = checkProps({
  className: PropTypes.string
});

Login.defaultProps = {};

export default memo(Login);
