// import React from 'react';
// import { useDispatch } from 'react-redux';
// import { useRouter } from 'next/router';
// import { gsap } from 'gsap';
import { Grid, Row, Col } from 'rsuite';
import SvgLogo from '../assets/svgs/evolve-logo.svg';
import Layout from '../components/Layout/Layout';
import styles from './index.module.scss';
import Head from '../components/Head/Head';
import Login from '../components/forms/Login/Login';
// import { setLandingLoaded } from '../redux/actions/app';

function Landing() {
  // const router = useRouter();
  // const dispatch = useDispatch();
  // const containerRef = useRef();

  // const animateInInit = useCallback(() => {
  //   gsap.set(containerRef.current, { autoAlpha: 0 });
  // }, []);

  // const animateIn = useCallback(async () => {
  //   await gsap.to(containerRef.current, { duration: 0.5, autoAlpha: 1, delay: 0.3 });
  //   dispatch(setLandingLoaded(true));
  // }, [dispatch]);

  // useEffect(() => {
  //   animateInInit();
  // }, [animateInInit]);

  // useEffect(() => {
  //   animateIn();
  // }, [animateIn]);

  return (
    <Layout isDashboard={false}>
      <div className={styles.Landing}>
        <Head />
        <Grid fluid>
          <Row className="show-grid">
            <Col xs={24} sm={24} md={8} className={styles.Landing__sidebar}>
              <SvgLogo />
              <h1 className={styles.title}>Plug it in. Turn it On. You’re Shipping!</h1>
            </Col>
            <Col xs={24} sm={24} md={16} className={styles.Landing__login}>
              <section className={styles.Landing__login__form}>
                <h1 className={styles.Landing__login__title}>Sign in to EvolveSCS</h1>
                <Login />
              </section>
            </Col>
          </Row>
        </Grid>
      </div>
    </Layout>
  );
}

export default Landing;
