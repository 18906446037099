import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { Dropdown, Button, Alert } from 'rsuite';
import checkProps from '@jam3/react-check-extra-props';
import { useRouter } from 'next/router';
import styles from './Nav.module.scss';
// import BellIcon from '../../assets/svgs/bell.svg';
import Hamburger from '../common/Hamburger/Hamburger';
import { get } from '../../lib/web';

function Nav({ isSubnav, toggleHandler, toggleSubMenu, pageTitle }) {
  const user = useSelector((state) => state.user);
  const router = useRouter();
  const signout = async () => {
    try {
      const { data } = await get('/api/logout');
      if (data) {
        Alert.success('You have successfully signed out!');
        router.push('/');
      }
    } catch (err) {
      Alert.error('You are already logout.');
    }
  };

  return (
    <nav className={classnames(styles.Nav)}>
      <div className={styles.wrapper}>
        <Hamburger
          isToggle={toggleSubMenu}
          handleToggle={toggleHandler}
          className={isSubnav ? styles.isSubnav : styles.hideSubnav}
        />
        <span className={styles.pageLabel}>{pageTitle || ''}</span>
      </div>
      <div className={styles.rightMenu}>
        {/* <Badge className={styles.badgeBox} content={19}>
          <BellIcon />{' '}
        </Badge> */}
        <Dropdown placement="bottomEnd" className={styles.userDropdown} title={user?.user?.name || 'User'}>
          {/* <Dropdown.Item>My Account</Dropdown.Item> */}
          <Dropdown.Item>
            <Button onClick={() => signout()} appearance="primary">
              Sign Out
            </Button>
          </Dropdown.Item>
        </Dropdown>
      </div>
    </nav>
  );
}

Nav.propTypes = checkProps({
  isSubnav: PropTypes.bool.isRequired,
  toggleHandler: PropTypes.func,
  toggleSubMenu: PropTypes.bool,
  pageTitle: PropTypes.string
});

Nav.defaultProps = { isSubnav: true };

export default Nav;
