import React, { memo } from 'react';
import classnames from 'classnames';
import checkProps from '@jam3/react-check-extra-props';

import styles from './Footer.module.scss';

function Footer() {
  return (
    <footer className={classnames(styles.Footer)}>
      <small>2021 © EvolveSCSWMS</small>
    </footer>
  );
}

Footer.propTypes = checkProps({});

Footer.defaultProps = {};

export default memo(Footer);
