import { FormGroup, FormControl, ControlLabel } from 'rsuite';

function TextField(props) {
  const { name, label, accepter, error, className, ...rest } = props;
  return (
    <FormGroup>
      <ControlLabel className={className}>{label} </ControlLabel>
      <FormControl className={error ? 'inValid' : ''} placeholder={' '} name={name} accepter={accepter} {...rest} />
    </FormGroup>
  );
}

export default TextField;
