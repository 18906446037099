import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import checkProps from '@jam3/react-check-extra-props';
import styles from './Hamburger.module.scss';

function Hamburger({ handleToggle, isToggle, className }) {
  return (
    <div className={classnames(styles.hamburger, isToggle ? 'activeHamburger' : '', className)} onClick={handleToggle}>
      <span className={styles.menuToggle}>
        <span className={classnames(styles.iconBoxToggle, isToggle ? styles.active : '')}>
          <span className={styles.rotate}>
            <i className={styles.iconLineTop} />
            <i className={styles.iconLineCenter} />
            <i className={styles.iconLineBottom} />
          </span>
        </span>
      </span>
    </div>
  );
}

Hamburger.propTypes = checkProps({ handleToggle: PropTypes.func, isToggle: PropTypes.bool, className: PropTypes.any });

Hamburger.defaultProps = {};

export default Hamburger;
