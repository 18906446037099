import React from 'react';
import { useRouter } from 'next/router';
import classnames from 'classnames';
import checkProps from '@jam3/react-check-extra-props';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { Tooltip, Whisper } from 'rsuite';
import styles from './VerticleNav.module.scss';
import SvgThreeLogo from '../../assets/svgs/svg-three-logo.svg';
import SvgWarehousesIcon from '../../assets/svgs/warehouse.svg';
import SvgWarehouseViewIcon from '../../assets/svgs/boxes.svg';
import SvgActivityIcon from '../../assets/svgs/activity.svg';
import SvgUsersIcon from '../../assets/svgs/group.svg';

const LINKS = [
  { href: '/dashboard', label: 'Dashboard', src: <SvgActivityIcon /> },
  { href: '/warehouse-view', label: 'Warehouse View', src: <SvgWarehouseViewIcon /> },
  { href: '/warehouses-management', label: 'Warehouse Management', src: <SvgWarehousesIcon /> },
  { href: '/users', label: 'User Management', src: <SvgUsersIcon /> }
].map((link, i) => ({
  ...link,
  key: `nav-link-${i}`
}));

function VerticleNav({ toggleActive }) {
  const router = useRouter();
  return (
    <nav className={classnames(styles.VerticleNav, toggleActive ? styles.active : '')}>
      <div className={styles.wrapper}>
        <div className={styles.logo}>
          <Link href={'/dashboard'}>
            <a aria-label="Home">
              <SvgThreeLogo className={styles.threeLogo} />
            </a>
          </Link>
        </div>
        <ul>
          {LINKS.map(({ key, href, label, src }) => (
            <li className={classnames(styles.link, router.pathname === href ? styles.active : '')} key={key}>
              <Link href={href}>
                <a>
                  <Whisper aria-label={label} trigger="hover" placement={'right'} speaker={<Tooltip>{label}</Tooltip>}>
                    {src}
                  </Whisper>
                </a>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
}

VerticleNav.propTypes = checkProps({
  toggleActive: PropTypes.any
});

VerticleNav.defaultProps = {};

export default VerticleNav;
