import { useCallback } from 'react';
import { post } from '../lib/web';

export default function usePost() {
  return useCallback(async (url, params, headers) => {
    let endpoint = process.env.NEXT_PUBLIC_BASE_URL;
    if (!url.includes('/api/v')) {
      endpoint = process.env.NEXT_PUBLIC_BASE_URL || 'http://localhost:5600';
    }
    console.log(endpoint);
    return post(`${endpoint}${url}`, params, headers);
  }, []);
}
