import { createSlice } from '@reduxjs/toolkit';

const app = createSlice({
  name: 'app',
  initialState: {
    loaded: false
  },
  reducers: {
    setLandingLoaded(state, action) {
      state.loaded = action.payload;
      return state;
    }
  }
});

export const { setLandingLoaded } = app.actions;
export default app.reducer;
